<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>saving-money-seedling</title>
    <rect
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x="1.585"
      y="2.292"
      width="9.238"
      height="6.007"
      rx="1"
      ry="1"
      transform="translate(3.48 -2.393) rotate(30.012)"
    />
    <rect
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x="13.055"
      y="6.099"
      width="9.509"
      height="6.007"
      rx="1"
      ry="1"
      transform="translate(37.439 5.27) rotate(141.884)"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="11.952"
      y1="20.25"
      x2="11.952"
      y2="0.933"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M6.134,4.764a.375.375,0,1,1-.375.375.374.374,0,0,1,.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M17.647,8.769a.375.375,0,1,1-.375.375.374.374,0,0,1,.375-.375"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M8.952,23.25l-.79-2.341a.5.5,0,0,1,.475-.659h6.62a.5.5,0,0,1,.475.659l-.78,2.341"
    />
    <rect
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x="5.457"
      y="12.927"
      width="6"
      height="3.75"
      rx="0.5"
      ry="0.5"
      transform="translate(5.993 -2.067) rotate(21.429)"
    />
  </svg>
</template>
